import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography, CardContent, Card, Box } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import SelectedCustomerData from "./SelectedCustomerData";

const validationSchema = Yup.object({
  startDate: Yup.date().required("From Date is required"),
  endDate: Yup.date().required("To Date is required")
});

const Report = () => {
  const [salesUserSelected, setSalesUserSelected] = useState("");
  const [salesUsersList, setSalesUsersList] = useState([]);
  const [listOfCustomerData, setListOfCustomerData] = useState([]);

  const handleSubmit = async (values) => {
    console.log(values, salesUserSelected);
    const startDateValue = new Date(values.startDate);
    const endDateValue = new Date(values.endDate);
    try {
      const q = query(
        collection(db, "visitInformation"),
        where("userId", "==", salesUserSelected),
      );

      const querySnapshot = await getDocs(q);
      var listOfCustomersInThisDate = [];

      querySnapshot.forEach((doc) => {
        const dateValue = doc.data().dateOfVisit;
        const dateParts = dateValue.split("-");
        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1; 
        const day = parseInt(dateParts[2]);

        const dateObject = new Date(year, month, day);
        if (dateObject >= startDateValue && dateObject <= endDateValue) {
          doc.data().listOfCustomers.forEach((visit) => listOfCustomersInThisDate.push({ "id": doc.id, "date": dateValue, "customerName": visit.customerName, "customerVisit": visit.customerVisit, "note": visit.note, "visitGoal": visit.visitGoal, "saleTarget": visit.saleTarget }))
        }

      });
      console.log(listOfCustomersInThisDate)
      setListOfCustomerData([])
      setListOfCustomerData(listOfCustomersInThisDate)
    } catch (error) {
      console.log(error);
    }
    // Perform your submit logic here
  };

  useEffect(() => {
    getSalesUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const getSalesUsers = async () => {
    const userArray = [];
    const q = query(collection(db, "users"), where("role", "==", 2));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      userArray.push({ uid: doc.data().uid, username: doc.get("username") });
    });
    setSalesUsersList(userArray);
  };

  return (

    <>
      <Navbar />
      <Box display="flex" justifyContent="center" padding={2} >
      <Card margin={3} padding={4} sx={{ width: "90%" }}>
        <Grid margin={1} padding={2} item xs={10}>
          <Formik
            initialValues={{
              startDate: "",
              endDate: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange }) => (
              <Form style={{ direction: 'rtl' }}>
                <Grid container spacing={2} alignItems="center">

                  {/* Start Date */}
                  <Grid item xs={12} md={3}>
                    <Typography variant="h5" component="h2">اختر تاريخ البداية</Typography>
                    <Field
                      name="startDate"
                      type="date"
                      as={TextField}
                      onChange={handleChange}
                      fullWidth
                      error={touched.startDate && !!errors.startDate}
                      helperText={touched.startDate && errors.startDate}
                    />
                  </Grid>

                  {/* End Date */}
                  <Grid item xs={12} md={3}>
                    <Typography variant="h5" component="h2">اختر تاريخ النهاية</Typography>
                    <Field
                      name="endDate"
                      type="date"
                      as={TextField}
                      onChange={handleChange}
                      fullWidth
                      error={touched.endDate && !!errors.endDate}
                      helperText={touched.endDate && errors.endDate}
                    />
                  </Grid>

                  {/* Sales User */}
                  <Grid item xs={12} md={3}>
                    <Typography variant="h5" component="h2">اختار مندوب المبيعات</Typography>
                    <FormControl fullWidth variant="filled">
                      <InputLabel id="salesUserSelected">اختار مندوب المبيعات</InputLabel>
                      <Select
                        disabled={salesUsersList.length === 0}
                        value={salesUserSelected}
                        onChange={(e) => {
                          setSalesUserSelected(e.target.value);
                        }}
                      >
                        {salesUsersList.map((e, index) => (
                          <MenuItem key={index} value={e.uid}>{e.username}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Search Button */}
                  <Grid item xs={12} md={3} mt={2} fullWidth>
                    <Button fullWidth type="submit" variant="contained" color="primary">
                      بحث
                    </Button>
                  </Grid>

                </Grid>
              </Form>

            )}
          </Formik>

        </Grid>
        <CardContent>
          {listOfCustomerData.length > 1 && <SelectedCustomerData listOfCustomerData={listOfCustomerData} />}
        </CardContent>
        {/* <CardActions>
        <Button size="small">Share</Button>
        <Button size="small">Learn More</Button>
      </CardActions> */}
      </Card>

      {/* <Grid container justifyContent="center">
      
       
      </Grid> */}
      </Box>
    </>

  );
};

export default Report;
