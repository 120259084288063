import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { FormControl, InputLabel, MenuItem, Select, Box, CssBaseline, ThemeProvider, createTheme, Button, FormControlLabel, Checkbox } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

const theme = createTheme({
    direction: 'rtl', // Set the direction to RTL
});


const SelectedCustomerData = ({ listOfCustomerData }) => {


    //optionally access the underlying virtualizer instance
    const rowVirtualizerInstanceRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [sorting, setSorting] = useState([]);
    const [selectedCustomerName, setSelectedCustomerName] = useState(''); // New state
    const [selectedDate, setSelectedDate] = useState(''); // New state
    const [removeDuplication, setRemoveDuplication] = useState(false); // New state for removing duplication
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const columns = useMemo(
        //column definitions...
        () => [
            {
                accessorKey: 'date',
                header: 'التاريخ',
                size: 150,
            },
            {
                accessorKey: 'customerName',
                header: 'اسم الزبون',
                size: 300,
            },
            {
                accessorKey: 'customerVisit',
                header: 'زيارة الزبون',
                size: 150,
            },

            {
                accessorKey: 'visitGoal',
                header: 'هدف الزيارة',
                size: 250,
            }, {
                accessorKey: 'note',
                header: 'الملاحظات',
                size: 100,
            }
        ],
        [],
        //end
    );
    const firstCustomerKeys = Object.keys(listOfCustomerData[0])
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        // headers: columns.map((c) => c.header),
        headers: firstCustomerKeys.map((c) => c),

    };

    const csvExporter = new ExportToCsv(csvOptions);
    const filteredData = useMemo(() => {
        setIsLoading(true);
        let result = [...listOfCustomerData];

        if (selectedCustomerName) {
            result = result.filter(item => item.customerName === selectedCustomerName);
        }

        if (selectedDate) {
            result = result.filter(item => item.date === selectedDate);
        }

        if (removeDuplication) {


            let newArray = [];

            // Declare an empty object
            let uniqueObject = {};

            // Loop for the array elements
            for (let i in result) {

                // Extract the title
                const objTitle = result[i]['customerName'];

                // Use the title as the index
                uniqueObject[objTitle] = result[i];
            }

            // Loop to push unique object into array
            for (let i in uniqueObject) {
                newArray.push(uniqueObject[i]);
            }


            result = newArray;
        }

        setIsLoading(false);

        return result;
    }, [listOfCustomerData, selectedCustomerName, selectedDate, removeDuplication]);



    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);
    const uniqueDates = [...new Set(listOfCustomerData.map(item => item.date))];
    const uniqueCustomerNames = [...new Set(listOfCustomerData.map(item => item.customerName))];


    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(listOfCustomerData);
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box dir="rtl"> {/* Ensure everything inside this Box is right-to-left */}
                <Box marginBottom={2} marginTop={3} display="flex" justifyContent="space-between" dir="rtl">

                    <FormControl fullWidth variant="standard" sx={{ marginX: 1, flexGrow: 1 }}>
                        <InputLabel id="selectDate">الكل</InputLabel>
                        <Select
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        >
                            <MenuItem value="">الكل</MenuItem>
                            {uniqueDates.map((date, index) => (
                                <MenuItem key={index} value={date}>{date}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth variant="standard" sx={{ marginX: 1, flexGrow: 1 }}>
                        <InputLabel id="salesUserSelected">اختار الزبون</InputLabel>
                        <Select
                            value={selectedCustomerName}
                            onChange={(e) => setSelectedCustomerName(e.target.value)}
                        >
                            <MenuItem value="">الجميع</MenuItem>
                            {uniqueCustomerNames.map((name, index) => (
                                <MenuItem key={index} value={name}>{name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
               
                </Box>
                <MaterialReactTable
                    columns={columns}
                    data={filteredData}
                    positionToolbarAlertBanner="bottom"
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{ display: 'flex', gap: '1rem', p: '1rem', flexWrap: 'wrap' }}
                        >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                استخراج كل البيانات
                            </Button>
                            <Button
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                    handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                استخراج البيانات في الجدول
                            </Button>
                            <FormControlLabel
                    fullWidth
                        control={
                            <Checkbox
                                checked={removeDuplication}
                                onChange={() => setRemoveDuplication(!removeDuplication)}
                            />
                        }
                        label="حذف التكرار"
                    />
                        </Box>
                    )}

                    enableDensityToggle={false}
                    onPaginationChange={setPagination}
                    enableRowNumbers
                    enableRowVirtualization
                    muiTableContainerProps={{ sx: { maxHeight: '600px' } }}
                    onSortingChange={setSorting}
                    state={{ isLoading, pagination, sorting }}
                    rowCount={filteredData?.length ?? 0}

                />
            </Box>
        </ThemeProvider>
    );
};

//virtualizerInstanceRef was renamed to rowVirtualizerInstanceRef in v1.5.0
//virtualizerProps was renamed to rowVirtualizerProps in v1.5.0

export default SelectedCustomerData;
